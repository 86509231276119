@keyframes custom-menu-enter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes custom-dialog-enter {
  from {
    transform: scale(0);
  }

  to {
    transform: none;
  }
}

cdk-dialog-container {
  outline: none;
}

.cdk-overlay-backdrop {
  background: rgba(57, 62, 85, 0.6);
  backdrop-filter: blur(2px);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-image {
  height: 2rem;
  width: 2rem;
  font-weight: var(--font-weight-bold);
  border-radius: 100%;
  background: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.cdk-overlay-container .input-panel {
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: var(--background-level-2);
  border-radius: var(--common-border-radius);
  padding: 0.5rem;
  grid-row: 2;
  grid-column: 1;
  transition: 0.125s ease all;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cdk-overlay-container .cdk-overlay-backdrop {
  animation: custom-menu-enter 0.35s ease;
}

.cdk-overlay-container [role="dialog"] .dialog-content {
  animation: custom-dialog-enter 0.35s ease;

  border-radius: var(--common-border-radius);
  background: var(--background-level-1);
  min-width: var(--modal-min-width);
  max-width: 90vw;
  max-height: 90vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .modal-header,
  .content,
  .actions {
    box-sizing: border-box;
  }

  .modal-header {
    padding-bottom: 1rem;

    i {
      color: var(--primary-text-faint-color);
      cursor: pointer;
      font-size: 1.5rem;

      &:hover {
        filter: brightness(95%);
      }
    }
  }

  .content {
    padding: 1.5rem 0rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }

  .actions-one {
    display: flex;
    justify-content: flex-end;
  }
}

.cdk-overlay-container .menu {
  padding: 0.5rem;
  border-radius: var(--input-border-radius);
  background-color: var(--background-level-0);
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  min-width: 150px;
  animation: custom-menu-enter 0.125s ease-in;
  border: 1px solid var(--background-level-3);

  &.light-mode {
    background-color: #fff;
  }

  a,
  button {
    text-decoration: none;
    padding: 0.5rem;
    box-sizing: content-box;
    margin-bottom: 0.5rem;
    transition:
      background 0.1s ease-in-out,
      color 0.1s ease-in-out;
    border-radius: var(--input-border-radius);
    fill: var(--primary-text-color);

    &[disabled] {
      color: var(--disabled-text-color);
      fill: var(--disabled-text-color);

      &:hover {
        cursor: default;
        background: inherit;
        color: var(--disabled-text-color);
        fill: var(--disabled-text-color);
      }
    }

    &:hover {
      cursor: pointer;
      background-color: var(--background-level-3);
      color: #fff;
      fill: var(--primary-color);
    }

    display: flex;
    align-items: center;
    flex-direction: row;
    // height: 1rem;
    background: none;
    border: none;
    border-image-width: 0;
    column-gap: 0.5rem;
    align-items: center;
    align-content: center;
    outline: none;
    text-align: left;

    img {
      height: 1em;
      line-height: 1em;
    }

    span {
      line-height: 1em;
      text-align: left;
      display: block;
      word-wrap: none;
    }

    &:last-child {
      margin-bottom: 0rem;
    }
  }

  .cdk-menu-trigger {
    &::after {
      display: block;
      content: "⟩";
    }
  }
}

.cdk-overlay-container .canvas-menu {
  button {
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 0.75rem;
  }
}

//Tabs
.tab-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--background-level-2);
  border-radius: var(--common-border-radius);
  padding: 0.5rem;

  .action {
    padding: 0.5rem 1rem;

    span {
      font-family: var(--primary-font-family);
      font-weight: var(--font-weight-bold);
    }

    &.active {
      background-color: var(--icon-color-alpha);
    }
  }
}

// Tables
.table {
  display: grid;

  .header {
    font-weight: bold;
  }

  .header,
  .row {
    grid-template-columns: 1fr 80px 80px 80px;
    display: grid;
    align-items: center;
    text-align: left;
    column-gap: 1rem;
    padding: 1rem;

    .number {
      text-align: right;
    }
  }

  .header {
    padding-top: 0rem;
  }

  .row {
    background-color: var(--background-level-2);
    border-radius: 0;
    padding: 1rem;
    margin-bottom: 0.5rem;

    &:nth-child(2) {
      border-radius: 8px 8px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }
  }
}

// Navbar
.navbar {
  height: var(--header-height);
  display: grid;
  padding: 1rem;
  align-items: center;
  column-gap: 3rem;

  img {
    height: 1.5rem;
  }

  a {
    display: block;
    text-decoration: none;
  }
}
