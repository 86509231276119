.banner-message {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: var(--common-border-radius);
  max-width: 40vw;

  label {
    text-align: left;
    line-height: 1.25rem;
  }

  span {
    grid-row: 1 / span 2;
  }

  &.warning {
    background-color: var(--warning-color-light);

    i,
    label {
      color: #846c46;
    }
  }

  &.info {
    background-color: var(--info-color);

    i,
    label {
      color: var(--primary-text-color);
    }
  }
}

.checkered-bg {
  --checker-size: 20px;
  --checker-primary-color: #ddd;
  --checker-alt-color: #aaa;

  --checker-half-size: calc(var(--checker-size) / 2);
  --checker-half-size-neg: calc(-1 * var(--checker-half-size));

  background-image: linear-gradient(45deg, var(--checker-alt-color) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--checker-alt-color) 75%),
    linear-gradient(45deg, transparent 75%, var(--checker-alt-color) 75%),
    linear-gradient(45deg, var(--checker-alt-color) 25%, var(--checker-primary-color) 25%);
  background-size: var(--checker-size) var(--checker-size);
  background-position:
    0 0,
    0 0,
    var(--checker-half-size-neg) var(--checker-half-size-neg),
    var(--checker-half-size) var(--checker-half-size);
}

.empty-state {
  padding: 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  background-color: var(--background-level-1);
  border-radius: var(--common-border-radius);

  p {
    font-size: 1rem;
    font-weight: 500;
    margin: 0rem;
  }
}

.status-tag {
  background: #fff;
  border-radius: 0.625rem;
  box-shadow: 0px 4px 12px 0px rgba(28, 31, 48, 0.08);
  color: var(--background-level-1);
  padding: 0.25rem 0.5rem;
  font-size: 0.5rem;
  font-weight: 400;
  line-height: normal;

  .label {
    font-weight: 700;
    margin: 0;
  }
}