//variables
@use "@angular/cdk/overlay-prebuilt.css";

@use "flag-icons/sass/flag-icons.scss" with (
  $flag-icons-path: "/assets",
  $flag-icons-rect-path: "/flags",
  $flag-icons-use-square: false
);

// TODO: Move this so it doesn't interfere with the rest of the styles - Looks like there are conflicts with the other styles
.tailwind {
  @import "tailwindcss/base";
  @import "tailwindcss/components";
  @import "tailwindcss/utilities";
}

:root {
  --base-rem-size: 16px;
  --base-font-size: 0.875rem;
  --primary-font-family: new-atten, sans-serif;
  --secondary-font-family: poppins, sans-serif;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-normal: 400;

  --common-border-radius: 10px;
  --input-border-radius: 0.5rem;

  --primary-background-color: #101324;

  --background-level-3: #393e55;
  --background-level-2: #292c3d;
  --background-level-1: #1c1f30;
  --background-level-0: #101324;
  --background-color-overlay: rgba(16, 19, 36, 0.4);

  --primary-text-color: #fff;
  --primary-text-faint-color: #ddd;

  --primary-color-rgb: 41, 144, 255;
  --primary-color-hsl: 211, 100%, 58%;

  --primary-color: #2990ff;
  --primary-darker-color: #1077e6;
  --primary-faint-color: rgba(41, 144, 255, 0.2);
  --primary-fainter-color: rgba(41, 144, 255, 0.1);

  --primary-alt-color: #19ffd9;
  --accent-color: #ff4294;
  --accent-color-alpha: rgba(255, 66, 148, 0.4);
  --accent-alt-color: #ce63ff;

  --error-color: #ba1f33;
  --success-color: #38b85b;
  --info-color: #009fb7;
  --warning-color: #e09a29;

  --disabled-color: #ccc;
  --disabled-text-color: #888;

  --input-placeholder-color: #c1c1cb;
  --input-background: #292c3d;
  --input-border-color: rgba(231, 231, 239, 0.2);

  --header-height: 35px;
  --header-alt-color: #333;

  --editor-left-panel-width: 100px;
  --editor-left-panel-details-width: 338px;

  --icon-height: 1rem;
  --icon-color: var(--primary-text-color);
  --icon-color-alpha: rgba(255, 255, 255, 0.2);
  --icon-hover-color: var(--primary-color);

  --nav-hover-bg: rgba(255, 255, 255, 0.05);
  --light-mode-minimal-hover: rgba(255, 255, 255, 0.2);
  --light-mode-level-4: #686c76;

  //Dialog properties
  --modal-min-width: 500px;

  // Light theme
  --error-color-light: #e46273;
  --success-color-light: #e0efda;
  --info-color-light: #92dce5;
  --warning-color-light: #ead2ac;

  // Establish audio color
  --media-player-color-background: var(--icon-color-alpha);
  --media-player-color: var(--icon-color);

  --upload-audio-icon: url(/assets/audio-dark.svg);
  --upload-image-icon: url(/assets/image-dark.svg);
  --upload-video-icon: url(/assets/video-dark-2.svg);

  //Decorative elements
  --common-box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  --beta-gradient: linear-gradient(to right, #ce63ff, #2990ff);
  --beta-gradient-to-left: linear-gradient(to left, #ce63ff, #2990ff);
  --gradient-premium: linear-gradient(to right, #ff4294, #ce63ff);
  --gradient-premium-to-left: linear-gradient(to left, #ff4294, #ce63ff);
}

:root .light-mode {
  --primary-text-color: #000;
  --primary-text-faint-color: #222;
  --media-player-color: #393e55;
  --media-player-color-background: rgba(59, 57, 85, 0.2);
  --background-level-3: #7f808b;
  --background-level-2: #c1c1cb;
  --background-level-1: #e7e7ef;
  --background-level-0: #fff;
  --input-border-color: #e7e7ef;
  --input-placeholder-color: #7f808b;
  --input-background: transparent;

  --upload-audio-icon: url(/assets/audio-light.svg);
  --upload-image-icon: url(/assets/image-light.svg);
  --upload-video-icon: url(/assets/video-light.svg);
  --upload-text-icon: url(/assets/text-light.svg);
}

@import "styles/standard.scss";
@import "styles/icon.scss";
@import "styles/inputs.scss";
@import "styles/container.scss";
@import "styles/loading.scss";
@import "styles/misc.scss";
@import "styles/select-theme.scss";
