body > *,
a,
button,
textarea,
select,
input {
  font-size: var(--base-font-size);
  font-family: var(--base-font-family);
  color: var(--primary-text-color);

  &:focus-visible {
    outline: var(--primary-color) solid 1px;
  }
}

:root .light-mode {
  & > *,
  a,
  button,
  textarea,
  select,
  input {
    color: var(--primary-text-color);
  }
}

html,
body {
  font-size: var(--base-rem-size);
  line-height: 1.5em;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--primary-background-color);
  overflow: hidden;
}

/*******************
Headings
*******************/
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-family: var(--primary-font-family);
}

h1 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: var(--font-weight-heavy);
}

h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: var(--font-weight-normal);
}

h3 {
  font-size: 1rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  font-weight: var(--font-weight-normal);
}

.display-heading-1,
.display-heading-2,
.display-heading-3 {
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2rem;
}

.display-heading-2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.display-heading-3 {
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: none;
}

body,
p {
  font-family: var(--primary-font-family);
  font-weight: var(--font-weight-normal);
  font-size: 0.875rem;
}

span {
  font-family: var(--primary-font-family);
}

label {
  font-weight: var(--font-weight-bold);
}

.helper {
  font-size: 0.75rem;
  font-style: italic;
}

hr.divider {
  height: 0.06125rem;
  border: none;
  width: 100%;
  background-color: var(--background-level-1);
  margin: 0;
}

hr.divider {
  &.divider-dark {
    background-color: var(--background-level-0);
  }
}

[appObserveElement].loader {
  opacity: 0 !important;
  pointer-events: none;
  margin-top: -20vh;
}
