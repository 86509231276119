label {
  user-select: none;
}

button.plain,
a {
  border: none;
  cursor: pointer;
  background: none;
  display: inline-flex;
  padding: 0rem;
}

.action {
  display: inline-flex;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--common-border-radius);
  box-sizing: border-box;
  font-weight: var(--font-weight-heavy);
  line-height: 1rem;
  cursor: pointer;
  transition: 0.25s ease-in-out all;
  user-select: none;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  column-gap: 0.25rem;

  i {
    // font-size:1.25rem;
    line-height: 1rem;
  }

  &.icon {
    display: grid;
    grid-template-rows: auto auto;
    transition: 0.125s ease-in-out all;
    text-align: center;

    span {
      font-size: 0.875rem;
    }
  }

  &.wide {
    padding: 0.5em 2rem;
  }

  &.default {
    &.basic {
      background: none;
      padding: 0.25rem;

      i.icon {
        line-height: 1em;
      }

      &:hover,
      &:focus {
        color: var(--primary-color);
      }
    }
  }

  &.primary {
    background-color: var(--primary-color);

    &:hover,
    &:focus {
      background: var(--primary-darker-color);
    }

    &.outline {
      border-color: var(--icon-color-alpha);
      color: var(--primary-text-color);
      background: none;

      &:hover,
      &:focus {
        background: var(--nav-hover-bg);
      }
    }

    &.basic {
      color: var(--primary-color);
      background: none;

      i.icon {
        line-height: 1em;
      }

      &:hover,
      &:focus {
        color: var(--primary-darker-color);
      }
    }

    &.more {
      border-radius: 100%;
      padding: 0.25rem;

      .icon {
        font-size: 1rem;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        background: var(--primary-faint-color);
      }
    }
  }

  &.danger {
    background-color: var(--error-color);

    &:hover {
      filter: brightness(95%);
      background: var(--error-color);
    }
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;

    &.outline {
      border-color: var(--disabled-color);
    }

    &.outline,
    & {
      background-color: var(--disabled-color);
    }

    &.basic {
      background-color: none;

      &:hover,
      &:focus,
      &:active {
        background-color: none;
      }
    }

    &,
    &.outline,
    &.basic {
      color: var(--disabled-text-color);

      &:hover,
      &:focus,
      &:active {
        color: var(--disabled-text-color);
        filter: none;
      }
    }
  }

  &.outline {
    vertical-align: unset;
    border: 1px solid;
    background-color: inherit;
    line-height: calc(1rem - 2px);
  }

  &.basic {
    border: none;
    padding: 0;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: var(--background-level-2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-level-3);
  border-radius: 0.5rem;
}

.form-control {
  label {
    cursor: pointer;
    user-select: none;
  }
}

.input-group {
  .form-errors {
    display: block;

    .input-group .form-errors {
      display: flex;
      align-content: center;
      align-items: center;
      column-gap: 0.5rem;
    }
  }
}

.input-group .form-errors .error,
html body .error.hint {
  font-size: 0.75rem;
  line-height: 0.85rem;
  color: var(--error-color-light);
  margin: 0.5rem 0rem;
  font-family: var(--primary-font-family);
  font-weight: var(--font-weight-bold);
}

input {

  &:-webkit-autofill,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  &:-webkit-autofill::first-line {
    color: var(--primary-text-color);
  }
}

input[type="range"] {
  cursor: pointer;
}

input[type="number"] {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  text-align: right;
}

div.select,
select,
input[type="url"],
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  background: var(--input-background);
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  font-family: var(--primary-font-family);
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: var(--input-placeholder-color);
  }
}

label.select {
  padding: 0px;
  position: relative;
  display: inline-block;

  select,
  div.select {

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    line-height: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: clip;
    padding-right: 2rem;
    text-align: left;
    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
  }

  &:hover::after {
    border-top-color: var(--primary-color);
  }

  &::after {
    pointer-events: none;
    position: absolute;
    content: "\e5cf";
    font-family: "Material Symbols Outlined";
    font-size: 1rem;
    bottom: 0.275rem;
    right: 0.5rem;
    font-variation-settings:
      "FILL" 1,
      "wght" 300,
      "GRAD" 0,
      "opsz" 48;
  }
}

// Context-button
button.context.action {
  background: var(--background-color-overlay);
  border-radius: var(--common-border-radius);
  color: var(--primary-text-color);
  transition: 0.25s ease-in-out opacity;
  position: absolute;
  padding: 0;
  margin: 0;
  vertical-align: top;
  top: 0.25rem;
  right: 0.25rem;
  height: 0.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;

  i {
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-variation-settings: "wght" 500;
  }

  &:hover {
    background-color: rgba(16, 19, 36, 0.8);
  }
}