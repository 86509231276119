// Preload
iframe.preload {
  width: 1px;
  height: 1px;
  opacity: 0.01;
  left: 0px;
  bottom: 0px;
}

div.preloader {
  width: 100vw;
  height: 100vh;
  background: rgba(var(--primary-color-rgb), 0.1);
  backdrop-filter: blur(4px);
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  .spinner,
  .spinner:after {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .spinner {
    left: 0;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1rem solid var(--spinner-color-alpha);
    border-right: 1.1rem solid var(--spinner-color-alpha);
    border-bottom: 1.1rem solid var(--spinner-color-alpha);
    border-left: 1.1rem solid var(--spinner-color);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinner-indefinite 1.1s infinite linear;
    animation: spinner-indefinite 1.1s infinite linear;
  }
}

body > div.preloader {
  --spinner-color-alpha: #ffffff88;
  --spinner-color: #fff;
  align-items: center;
}

body app-root[entry].main .placeholder-loading {
  background: var(--background-level-3);
  background: linear-gradient(
    to right,
    var(--primary-fainter-color) 4%,
    var(--primary-faint-color) 25%,
    var(--primary-fainter-color) 36%
  );
  background-size: 1000px 100%;
  -webkit-animation: shimmer 2s infinite linear;
  -webkit-animation-delay: 100ms;
  animation: shimmer 2s infinite linear;
  animation-delay: 100ms;
  opacity: 0;
  transition: 0.25s ease-in-out opacity;
  pointer-events: none;
  cursor: default;
  // transform: translateX(0%);

  * {
    pointer-events: none;
    cursor: default;
    transition: 0.25s ease-in-out opacity;
    opacity: 0 !important;
  }
}

@-webkit-keyframes spinner-indefinite {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-indefinite {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -1000px 0;
    opacity: 1;
  }

  100% {
    background-position: 1000px 0;
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
    opacity: 1;
  }

  100% {
    background-position: 1000px 0;
    opacity: 1;
  }
}

img.preload-image {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  height: 1px;
  width: 1px;
}

.cdk-virtual-scroll-content-wrapper .scroll-wrapper,
.infinite.scroll-wrapper {
  & > :not(.loaded) {
    animation: staggered-load 0.2s ease-in-out forwards;
    opacity: 0;
  }

  & > *,
  &.speed-1 > * {
    @for $i from 0 to 19 {
      &:nth-child(#{$i}) {
        animation-delay: #{($i * 0.005)}s;
      }
    }
    &:nth-child(n + 19) {
      animation-delay: #{20 * 0.005}s;
    }
  }

  &.speed-2 > * {
    @for $i from 0 to 19 {
      &:nth-child(#{$i}) {
        animation-delay: #{($i * 0.01)}s;
      }
    }
    &:nth-child(n + 19) {
      animation-delay: #{20 * 0.01}s;
    }
  }

  &.speed-3 > * {
    @for $i from 0 to 19 {
      &:nth-child(#{$i}) {
        animation-delay: #{($i * 0.02)}s;
      }
    }
    &:nth-child(n + 19) {
      animation-delay: #{20 * 0.02}s;
    }
  }
}

@keyframes staggered-load {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
