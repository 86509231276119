@mixin rtl {
  @at-root [dir="rtl"] #{&} {
    @content
  }
}

$ng-select-highlight: rgba(255, 255, 255, .05) !default;
$ng-select-primary-text: #fff !default;
$ng-select-disabled-text: #888 !default;
$ng-select-border: rgba(231, 231, 239, 0.2) !default;
$ng-select-border-radius: 0.625rem !default;
$ng-select-bg: var(--input-background) !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-selected-text: $ng-select-primary-text !default;

$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: #888 !default;
$ng-select-height: 36px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: $ng-select-border !default;
$ng-select-dropdown-optgroup-text: #fff !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: #fff !default;
$ng-select-dropdown-option-disabled: #888 !default;

$ng-select-input-text: var(--primary-text-color) !default;

.ng-select {
  &.error {
    .ng-select-container {
      border: 1px solid #E46273;
    }
  }

  &.ng-select-opened {
    >.ng-select-container {
      background: transparent;
      border-color: darken($ng-select-border, 10) $ng-select-border lighten($ng-select-border, 5);

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        top: -2px;
        border-color: transparent transparent #FFF;
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent darken(#FFF, 60);
        }
      }
    }

    &.ng-select-top {
      >.ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }

    &.ng-select-right {
      >.ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.ng-select-bottom {
      >.ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-left {
      >.ng-select-container {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened)>.ng-select-container {
      border-color: $ng-select-highlight;
      box-shadow: $ng-select-box-shadow;
    }
  }

  &.ng-select-disabled {
    >.ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: transparent;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;

    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }

    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;

      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }

      .ng-placeholder {
        color: $ng-select-placeholder;
        font-weight: 400;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;

      .ng-value-container {
        .ng-input {
          top: 5px;
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;

          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 50px;
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      >.ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;

        @include rtl {
          padding-right: 7px;
          padding-left: 0
        }

        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          color: $ng-select-value-text;
          background-color: $ng-select-selected;
          border-radius: 2px;
          margin-right: 5px;

          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }

          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;

            .ng-value-label {
              padding-left: 5px;

              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;

            &:hover {
              background-color: darken($ng-select-selected, 5);
            }

            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);

              @include rtl {
                border-left: 1px solid darken($ng-select-selected, 10);
                border-right: none;
              }
            }

            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);

              @include rtl {
                border-left: 0;
                border-right: 1px solid darken($ng-select-selected, 10);
              }
            }
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;

          @include rtl {
            padding: 0 3px 3px 0;
          }

          >input {
            color: $ng-select-input-text;
          }
        }

        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;

          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: darken($ng-select-border, 20);

    &:hover .ng-clear {
      color: #D0021B;
    }

    &:focus .ng-clear {
      color: #D0021B;
    }

    &:focus {
      outline: none;
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;

    @include rtl {
      padding-left: 5px;
      padding-right: 0;
    }

    &:hover {
      .ng-arrow {
        border-top-color: darken(#FFF, 40);
      }
    }

    .ng-arrow {
      border-color: darken(#FFF, 20) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-dropdown-bg;
  border: 1px solid $ng-select-dropdown-border;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: $ng-select-border-radius;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }

  &.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
        }
      }
    }
  }

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-top-color: lighten($ng-select-border, 10);
    margin-top: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $ng-select-border-radius;
          border-bottom-left-radius: $ng-select-border-radius;
        }
      }
    }
  }

  &.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: $ng-select-dropdown-optgroup-text;
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-dropdown-optgroup-marked;
        background-color: $ng-select-selected;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: $ng-select-dropdown-option-bg;
      color: $ng-select-dropdown-option-text;
      padding: 8px 10px;

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-selected-text;
        background-color: $ng-select-selected;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-marked-text;
      }

      &.ng-option-disabled {
        color: $ng-select-dropdown-option-disabled;
      }

      &.ng-option-child {
        padding-left: 22px;

        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;

        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}