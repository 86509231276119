@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 100 700;
  src: url("/assets/fonts/Material_Symbols_Outlined.woff") format("woff2");
}

.icon {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: var(--icon-height);
  line-height: 1em;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  user-select: none;
  font-variation-settings:
    "FILL" 1,
    "wght" 300,
    "GRAD" 0,
    "opsz" 48;

  &.error {
    color: var(--accent-color);
  }
}

.icon.outline {
  font-variation-settings:
    "FILL" 0,
    "wght" 300,
    "GRAD" 0,
    "opsz" 48;
}

.actions-menu,
.preview i {
  font-variation-settings: "wght" 500;
}
